<template>
  <div v-if="!store.authenticated">
    <div class="alert-info">
      <div class="hint">
        <span class="icon-info-before icon"></span>
        {{ t('You must login to use this formular') }}
      </div>

      <e-web-button buttonType="alpha" class="submit-button" @click="loginUser">
        {{ t('Login now') }}
      </e-web-button>

    </div>
  </div>
  <div v-else class="satisfaction-form">
    <div class="mb-3 p-3" v-if="store.message.value && store.message.type"
         :class="{ 'alert-success': store.message.type === 'success', 'alert-danger': store.message.type === 'error'}"
    >
      {{ store.message.value }}
    </div>
    <h3>{{ t('Identify product') }}</h3>
    <div class="mb-3 row g-0" v-for="(item,index) in form.items" :key="index">
      <div class="col-7 pe-2">
        <div class="row g-0">
          <div class="pe-2 col-10">
            <e-input
                :label="t('Serialnumber')"
                v-model="v.items.$model[index].serialNumber"
                :required="true"
                :error="!item.valid"
                @change="event => {checkserialNumber(event.target.value, index)}"
                :helper-text="(!item.valid && item.serialNumber === '')? t('Value is required'): ''"
            ></e-input>
          </div>
          <div class="col-2 ps-2 serials__serial"
               :class="{'-success': item.valid && item.serialNumber !== '', '-error': !item.valid && item.serialNumber !== ''}">
            <div class="serial__status">
              <div class="status__icon status__icon--checking"></div>
              <div class="status__icon status__icon--success"></div>
              <div class="status__icon status__icon--error">
                <span v-if="!item.valid" class="icon__message">
                  <template v-if="!item.productFamily">{{ t('Your serial number is invalid') }}</template>
                  <template v-else-if="item.productFamily !== 'P-X-C'">{{ t('This item can not be registered') }}</template>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="input-help">
        <a @click="triggerPopup(index)" data-popup="serialnumber-help">
              <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19" style="width: 20px; height: 20px"><g
                  fill="none" fill-rule="evenodd"
                  transform="translate(-129.000000, -1196.000000) translate(130.000000, 566.000000) translate(0.000000, 511.000000) translate(0.000000, 119.000000)"><ellipse
                  cx="9" cy="9.41176471" stroke="#E31F25" stroke-width="1.17647059" rx="9" ry="8.82352941"></ellipse><text
                  fill="#E31F25" font-family=".AppleSystemUIFont" font-size="14.1176471"><tspan x="5.76024816"
                                                                                                y="14">?</tspan></text></g></svg></span>
          {{ t('Where do I find the serial number?') }}
        </a>
        <div class="popup serialnumber-help" :class="{ '-visible': serialNumberPopShow[index] }">
          <div class="help__text">
            <div class="popup__close" @click="triggerPopup(index)"><span></span></div>
            <div class="popup-wrapper">
              <div class="popup-content popup-content--left">
                <img alt="Einhell Typenschild Seriennummer"
                     src="/fileadmin/corporate-media/services/warranties/einhell-services-warranty-serial-number-plate.png"
                     width="186" height="218">
              </div>
              <div class="popup-content popup-content--right">
                <div class="popup__text">
                  <span class="heading delta">{{ t('serial_number.help.header') }}</span>
                  <p>{{ t('serial_number.help.text') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-5 ps-2">
        <div class="serial__identified" :class="{'-success': item.valid && item.serialNumber !== '' && item.description !== ''}">
          <div class="identified__delete" data-id="0" @click="removeItem(index)">
            <span></span>
          </div>
          <div class="identified__name">
            <span class="value"><span class="desc2">{{item.tpgName}}</span> <span class="desc">{{item.description}}</span></span>
          </div>
          <div class="identified__serial">
            <span>{{ t('Serialnumber') }}: <span class="value">{{ item.serialNumber }}</span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 divider"></div>
    <h3>{{ t('Additional purchase information') }}</h3>
    <div class="mb-3">
      <e-datepicker
          :label="t('Purchasedate')"
          v-model="form.purchaseDate"
          :required="true"
          :options="{ max: todayDate, min: lastDate }"
          :error="v.purchaseDate.$error"
          :helper-text="getErrorMessage(v.purchaseDate.$errors)"
      ></e-datepicker>
    </div>
    <div class="mb-3">
      <e-input
          :label="t('Dealer')"
          v-model="form.dealer"
          :required="true"
          :error="v.dealer.$error"
          :helper-text="getErrorMessage(v.dealer.$errors)"
      ></e-input>
    </div>
    <div class="mb-3">
      <e-input
          :label="t('Purchasenumber')"
          v-model="form.purchaseNumber"
          :required="true"
          :error="v.purchaseNumber.$error"
          :helper-text="getErrorMessage(v.purchaseNumber.$errors)"
      ></e-input>
    </div>
    <h3>{{ t('Please help us improving the quality of our products') }}</h3>
    <div class="mb-3">
      <span :class="{'text-danger': v.returnReason.$error}">{{ t('Reason of the return shipment') }}*</span>
      <e-radio v-for="(symptom, index) in scaSymptoms" :key="index"
               :label="symptom.label"
               :value="symptom.label"
               :required="true"
               :group-name="'sca'"
               v-model="form.returnReason"
               :class="{'text-danger': v.returnReason.$error}"
      ></e-radio>
    </div>
    <h3>{{ t('Contact information') }}</h3>
    <div class="mb-3">
      <e-input
          :label="t('E-Mail')"
          v-model="form.email"
          :required="true"
          :error="v.email.$error"
          :helper-text="getErrorMessage(v.email.$errors)"
      ></e-input>
    </div>
    <div class="mb-3">
      <div class="row g-0">
        <div class="col-6 pe-2">
          <e-input
              :label="t('Firstname')"
              v-model="form.firstName"
              :required="true"
              :error="v.firstName.$error"
              :helper-text="getErrorMessage(v.firstName.$errors)"
          ></e-input>
        </div>
        <div class="col-6 ps-2">
          <e-input
              :label="t('Lastname')"
              v-model="form.lastName"
              :required="true"
              :error="v.lastName.$error"
              :helper-text="getErrorMessage(v.lastName.$errors)"
          ></e-input>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="row g-0">
        <div class="col-9 pe-2">
          <e-input
              :label="t('Address')"
              v-model="form.address"
              :required="true"
              :error="v.address.$error"
              :helper-text="getErrorMessage(v.address.$errors)"
          ></e-input>
        </div>
        <div class="col-3 ps-">
          <e-input
              :label="t('Housenumber')"
              v-model="form.houseNumber"
              :required="true"
              :error="v.houseNumber.$error"
              :helper-text="getErrorMessage(v.houseNumber.$errors)"
          ></e-input>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <div class="row g-0">
        <div class="col-3 pe-2">
          <e-input
              :label="t('Zip')"
              v-model="form.zip"
              :required="true"
              :error="v.zip.$error"
              :helper-text="getErrorMessage(v.zip.$errors)"
          ></e-input>
        </div>
        <div class="col-9 ps-2">
          <e-input
              :label="t('City')"
              v-model="form.city"
              :required="true"
              :error="v.city.$error"
              :helper-text="getErrorMessage(v.city.$errors)"
          ></e-input>
        </div>
      </div>
    </div>
    <div class="mb-3" style="overflow: visible" v-if="showCountry">
      <e-select-field
          :label="t('Country')"
          v-model="form.country"
          :required="true"
          :error="v.country.$error"
          :options="countries"
          :helper-text="getErrorMessage(v.country.$errors)"
      ></e-select-field>
    </div>
    <div class="mb-3">
      <e-checkbox
          v-model="form.acceptConditions"
          :label="t('Accept Conditions')"
          :required="true"
          :error="v.acceptConditions.$error"
      ></e-checkbox>
    </div>
    <div class="mb-3">
      <e-web-button @click="submit"
      >{{ t("Submit") }}
      </e-web-button>
    </div>
    <div class="mb-3 mandatory">
      <p>{{ t('mandatory.information') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, reactive} from 'vue'
import {useI18n} from "vue-i18n";
import useVuelidate from '@vuelidate/core'
import {required, email} from '@vuelidate/validators'
import {checkCipForCountry} from "@/service/Validators";
import {SatisfactionGuaranteeService} from "@/service/SatisfactionGuaranteeService";
import {SerialNumberService} from "@/service/SerialNumberService";
import {SatisfactionGuaranteeServiceType} from "@/Types/SatisfactionGuaranteeServiceType";
import dayjs from "dayjs";
import {store} from "@/store";
import {availableCountries, getLocale, systemDomain} from "@/main";
import {KeycloakService} from "@/service/KeycloakService";
import {ItemRepoService} from "@/service/ItemRepoService";
import {ConfigFileService} from "@/service/ConfigFileService";

export default defineComponent({
  name: 'SatisfactionGuaranteeFormular',
  setup() {
    const form = reactive({
      items: [
        {serialNumber: '', valid: true, description: '', tpgName: '', productFamily: ''}
      ],
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      houseNumber: '',
      zip: '',
      city: '',
      country: '',
      purchaseDate: '',
      purchaseNumber: '',
      dealer: '',
      returnReason: '',
      acceptConditions: false,
    })
    const rules = {
      items: {
        $each: {
          serialNumber: {required}
        },
        required
      },
      email: {required, email},
      firstName: {required},
      lastName: {required},
      address: {required},
      houseNumber: {required},
      zip: {required, checkCipForCountry},
      city: {required},
      country: {required},
      purchaseDate: {required},
      purchaseNumber: {required},
      dealer: {required},
      returnReason: {required},
      acceptConditions: {
        checked(val: boolean) {
          return val
        }
      },
    }
    const {t} = useI18n()
    const v = useVuelidate(rules, form)
    const formError = ref<string>("")
    const serialNumberInvalid = ref<boolean>(false)
    const serialNumberPopShow = ref<boolean[]>([false])
    setUserDataBasedOnToken(<string>KeycloakService.token)
    const countries = ref<{ value: string | number, label: string }[]>([])
    const availableFormCountries = availableCountries.split(",")
    availableFormCountries.forEach(function (country: string) {
      countries.value.push({value: country, label: t(country)})
    })
    const showCountry = ref<boolean>(true)
    if (availableFormCountries.length == 1) {
      form.country = availableFormCountries[0]
      showCountry.value = false
    }
    const scaSymptoms = ref<{ value: string | number, label: string }[]>([
      {value: '1', label: t('Battery life is not sufficient')},
      {value: '2', label: t('The work result does not meet my expectations')},
      {value: '3', label: t('Inconvenient handling')},
      {value: '4', label: t('A competitor\'s product better fits my needs')},
      {value: '5', label: t('The product is defective')},
      {value: '6', label: t('Issues with handling the product')},
      {value: '7', label: t('Other reasons')},
    ])
    const todayDate = dayjs().format('YYYY-MM-DD')
    const lastDate = dayjs().add(-30, "day").format('YYYY-MM-DD')

    const loginUser = (): void => {
      const loginUrl = localStorage.getItem('einhell-login-data')
      if (loginUrl) {
        const uri = JSON.parse(loginUrl)
        window.open(uri.loginUrl, '_self')
      }
    }

    function triggerPopup(index:number) {
      serialNumberPopShow.value[index] = !serialNumberPopShow.value[index]
    }

    function getErrorMessage(errors: { $validator: string, $message: string }[]) {
      let message = ""
      errors.forEach(function (error) {
        message += " " + t(error.$message)
        if (error.$validator == "checkCipForCountry") {
          message += " " + t("not valid")
        }
      })
      return message
    }

    function itemValid() {
      let itemsValid = true
      form.items.forEach((item, index) => {
        if (item.serialNumber == "") {
          if (form.items.length == 1){
            item.valid = false
          }
        }
        if (!item.valid || (item.productFamily !== "P-X-C" && item.serialNumber != "")) {
          itemsValid = false
        }
      })
      return itemsValid
    }

    function setUserDataBasedOnToken(token: string) {
      try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const parsedTokenObject = JSON.parse(jsonPayload)
        form.email = parsedTokenObject.email || ""
        form.firstName = parsedTokenObject.given_name || ""
        form.lastName = parsedTokenObject.family_name || ""
      } catch (error) {
      }
    }

    function clearItems(items:{serialNumber:string}[]) {
        let clearedItems:{serialNumber:string}[] = []
        items.forEach(item => {
          console.log(item)
          if (item.serialNumber !== ""){
            clearedItems.push(item)
          }
        })
        return clearedItems
    }

    function submit() {
      store.message.value = ""
      store.message.type = ""
      v.value.$touch()
      if (itemValid() && !v.value.$invalid) {
        const satisfactionGuaranteeServiceData: SatisfactionGuaranteeServiceType = {
          homepage: systemDomain,
          itemInfo: {
            items: clearItems(form.items),
            statusNo: form.country == "de" ? 200 : 202,
            locale: getLocale(),
          },
          retoureInfo: {
            purchaseDate: dayjs(form.purchaseDate).format('MM/DD/YYYY'),
            dealer: form.dealer,
            purchaseNumber: form.purchaseNumber,
            failureRemark: form.returnReason
          },
          address: {
            firstName: form.firstName,
            lastName: form.lastName,
            street: form.address,
            buildingNo: form.houseNumber,
            cip: form.zip,
            city: form.city,
            country: form.country,
            email: form.email,
            phone: '',
          }
        }

        SatisfactionGuaranteeService.create(satisfactionGuaranteeServiceData)
            .then(value => {
              localStorage.setItem("satisfaction-guarantee-download", JSON.stringify(value))
              window.location.href = ConfigFileService.successUrl
            })
            .catch(error => {
              store.message.value = error.message
              store.message.type = "error"
              window.scrollTo(0, 0);
            })
      }
    }

    function checkserialNumber(serialNumber: string, index: number) {
      if (serialNumber == '') {
        form.items[index].valid = false
        removeItem(index + 1)
      } else {
        SerialNumberService.check(serialNumber)
            .then(value => {
              form.items[index] = value
              form.items[index].valid = true
              checkSameSerialNumber(serialNumber,index)
              if (form.items[index].valid) {
                ItemRepoService.getItem(value.itemNo + "_" + value.itemVersion)
                .then(value => {
                  form.items[index].description = value.description
                  form.items[index].tpgName = value.tpgName
                  form.items[index].productFamily = value.productFamily
                  if (value.productFamily == "P-X-C"){
                    addItem()
                  } else {
                    form.items[index].valid = false
                  }
                })
              }
            })
            .catch(error => {
              console.log(error)
              form.items[index].valid = false
            })
      }
    }

    function checkSameSerialNumber(serialNumber: string, index: number) {
      form.items.forEach(function (item, i) {
        if (i !== index) {
          if (serialNumber.toUpperCase() == item.serialNumber.toUpperCase()){
            form.items[index].valid = false
            return
          }
        }
      })
    }

    function addItem() {
      if (form.items.length < 3) {
        form.items.push({serialNumber: '', valid: true, description: '', tpgName: '', productFamily: ''})
      }
    }

    function removeItem(index:number) {
      if (form.items.length > 1) {
        form.items.splice(index, 1)
        serialNumberPopShow.value.splice(index, 1)
      } else {
        form.items[index].serialNumber = ""
      }
    }

    return {
      t,
      v,
      form,
      formError,
      serialNumberInvalid,
      todayDate,
      lastDate,
      countries,
      showCountry,
      scaSymptoms,
      serialNumberPopShow,
      triggerPopup,
      getErrorMessage,
      submit,
      checkserialNumber,
      removeItem,
      loginUser,
      store
    }
  }
})
</script>

<style lang="scss" scoped>
.satisfaction-form {
  @import "~bootstrap/dist/css/bootstrap-grid.min.css";
}
</style>

<style scoped>
h3 {
  margin-bottom: 0.8em !important;
}

.satisfaction-form .text-danger {
  color: rgba(220, 53, 69);
}

.delta, h4 {
  font-size: 1.2em;
}

.heading, h1, h2, h3, h4, h5, h6 {
  display: block;
  line-height: 1.4;
  color: #1f2126;
  font-family: Open Sans Condensed, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
}

.paragraph, p {
  margin: 0 0 1em;
}

@media (min-width: 90em) {
  .input-help {
    margin-top: 1em;
    margin-bottom: 1.5625em;
  }
}

.input-help {
  position: relative;
  margin-top: 1em;
  margin-bottom: 1.44em;
}

.popup {
  opacity: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 1.5em;
  pointer-events: none;
  border: 1px solid rgba(31, 33, 38, .25);
  background-color: #fff;
  box-shadow: 3px 3px 0 0 rgb(31 33 38 / 8%);
  z-index: 10;
  transform: scale(1.02);
  transition: opacity .35s cubic-bezier(.215, .61, .355, 1), transform .35s cubic-bezier(.215, .61, .355, 1);
}

.popup.-visible {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

.popup .popup__close {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: -15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid rgba(31, 33, 38, .25);
  background-color: #fff;
  box-shadow: 2px 2px 0 0 rgb(31 33 38 / 8%);
  transition: background .25s cubic-bezier(.215, .61, .355, 1);
}

.popup .popup__close span {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
}

.popup .popup__close span:after, .popup .popup__close span:before {
  content: "";
  display: block;
  position: absolute;
  width: 22px;
  height: 4px;
  border-radius: 1px;
  background-color: #d50c1f;
  transform-origin: 0 50%;
  transition: background .25s cubic-bezier(.215, .61, .355, 1);
}

.popup .popup__close span:before {
  left: 2px;
  top: 0;
  transform: rotate(45deg);
}

.popup .popup__close span:after {
  left: 18px;
  top: 0;
  transform: rotate(135deg);
}

.popup .popup-wrapper .popup-content--left {
  float: right
}


.serial__status {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(1.8em + 34px);
  border-radius: 3px;
  background-color: #f7f7f8;
  transition: background .25s cubic-bezier(.215, .61, .355, 1);
}

.serials__serial.-success .serial__status {
  background-color: #27ae60
}

.serials__serial.-success .status__icon--success {
  opacity: 1
}

.serials__serial.-error .serial__status {
  background-color: #961414
}

.serials__serial.-error .status__icon--error {
  opacity: 1
}

.serial__status .status__icon.status__icon--success:after, .serial__status .status__icon.status__icon--success:before {
  content: "";
  display: block;
  position: absolute;
  height: 4px;
  border-radius: 1px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.serial__status .status__icon.status__icon--success:before {
  width: 0;
  left: 2px;
  top: 7px;
  transform: rotate(45deg);
  transition: width .15s linear .15s
}

.serial__status .status__icon.status__icon--success:after {
  width: 0;
  left: 7px;
  top: 14px;
  transform: rotate(-45deg);
  transition: width .25s cubic-bezier(.215, .61, .355, 1) .3s
}

.serials__serial.-success .status__icon--success:before {
  width: 10px
}

.serials__serial.-success .status__icon--success:after {
  width: 16px
}

.serial__status .status__icon {
  opacity: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
}

.serial__status .status__icon.status__icon--error:before {
  left: 2px;
  top: 0;
  transform: rotate(45deg);
  transition: width .25s cubic-bezier(.215, .61, .355, 1) .15s;
}

.serial__status .status__icon.status__icon--error:after {
  left: 18px;
  top: 0;
  transform: rotate(135deg);
  transition: width .25s cubic-bezier(.215, .61, .355, 1) .4s;
}

.serial__status .status__icon.status__icon--error:after, .serial__status .status__icon.status__icon--error:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 4px;
  border-radius: 1px;
  background-color: #fff;
  transform-origin: 0 50%;
}

.serials__serial.-error .status__icon--error:after, .serials__serial.-error .status__icon--error:before {
  width: 22px
}


@media (min-width: 90em) {
  .serial__status .status__icon.status__icon--error .icon__message {
    font-size: .8em;
  }
}

.serial__status .status__icon.status__icon--error .icon__message {
  display: none;
  position: absolute;
  left: 50%;
  top: calc(100% + 30px);
  color: #fff;
  font-weight: 700;
  font-size: .83333em;
  padding: 5px 15px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: #961414;
  transform: translateX(-50%);
}

@media (min-width: 64em) {
  .serial__status .status__icon.status__icon--error .icon__message {
    display: block;
  }
}

.serial__status .status__icon.status__icon--error .icon__message:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  top: -4px;
  margin-left: -5px;
  border-radius: 3px;
  background-color: #961414;
  transform: rotate(45deg);
  z-index: 0;
}

.divider {
  border-bottom: 1px solid rgba(31,33,38,.25);
}

.item-remove-button{
  height: calc(1.8em + 32px);
}

.serial__identified.-success {
  opacity: 1;
}

.serial__identified {
  position: relative;
  opacity: 0;
  padding: 0.65em 1.2em;
  padding-right: 40px!important;
  border: 1px solid rgba(31,33,38,.25);
  background-color: #fff;
  box-shadow: 3px 3px 0 0 rgb(31 33 38 / 8%);
  transition: opacity .35s cubic-bezier(.215,.61,.355,1);
}

@media (min-width: 90em) {
 .serial__identified {
    padding: 0.75em 1.25em;
   max-height: 62.8px;
  }
}

.serial__identified .identified__delete {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: .6em;
  top: .6em;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
  background-color: #fff
}

.serial__identified .identified__delete span {
  display: block;
  position: relative;
  width: 11px;
  height: 11px
}

.serial__identified .identified__delete span:after,.serial__identified .identified__delete span:before {
  content: "";
  display: block;
  position: absolute;
  width: 12px;
  height: 3px;
  border-radius: 1px;
  background-color: #d50c1f;
  transform-origin: 0 50%
}

.serial__identified .identified__delete span:before {
  left: 1px;
  top: 0;
  transform: rotate(45deg)
}

.serial__identified .identified__delete span:after {
  left: 10px;
  top: 0;
  transform: rotate(135deg)
}

.serial__identified .identified__name span {
  display: inline-block;
  line-height: 1.2;
  color: #d50c1f;
  font-weight: 700
}

.serial__identified .identified__serial,
.serial__identified .identified__name {
  line-height: 1.2;
}

.serial__identified .identified__serial span {
  line-height: 1.2;
  font-weight: 700;
  font-size: .83333em
}

@media (min-width: 90em) {
 .serial__identified .identified__serial span {
    font-size:.8em
  }
}

@media (min-width: 64em) {
 .serial__identified .identified__serial span {
    display:inline-block
  }
}

.satisfaction-form h3 {
  margin-top: 1.5em;
}

.alert-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid var(--primary);
  padding: 12px;
}

.icon {
  font-family: icomoon;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.icon-info-before:before {
  content: "\e900";
  margin-right: 0.8em;
}
</style>
