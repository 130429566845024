import {ConfigFileService} from "@/service/ConfigFileService";
import {KeycloakService} from "@/service/KeycloakService";
import {createApp} from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue"
import eVueComponents from "@shared/e-vue-components";
import "@/assets/css/template.css"
import {store} from "@/store";

function loadLocaleMessages () {
    const locales = require.context('./lang', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            messages[locale] = locales(key)
        }
    })
    return messages
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const systemDomain = window.vueAppDomain || "einhell.de"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const systemLanguageCode = window.vueAppLanugageCode || "de_DE"
// @ts-ignore
export const availableCountries = window.vueAppCountries || "de"

export function getLocale() {
    const lang = systemLanguageCode
    return lang.substr(0, 2)
}

function registerStorageListener(): void {
    setTimeout(() => {
        const authData = localStorage.getItem("einhell-auth-data")

        if (authData) {
            store.authenticated = true
        } else {
            store.authenticated = false
        }
        registerStorageListener()
    }, 1000)
}

localStorage.removeItem("satisfaction-guarantee-download")

export const i18n = createI18n({ locale: getLocale(), fallbackLocale: 'en', messages: loadLocaleMessages(), legacy: false })

ConfigFileService.init()
    .then(() => {
        const token = KeycloakService.token
        if (token){
            store.authenticated = true
        }
        registerStorageListener()
        createApp(App)
            .use(i18n)
            .use(eVueComponents)
            .mount('#satisfaction-guarantee-app')
    })
    .catch((reason: Error) => {
        console.error(reason)
        // transform error to object ( error has no toJson implementation )
        let textReason = ''
        if (reason) {
            textReason = JSON.stringify(
                Object.assign(
                    Object.assign(
                        {},
                        {
                            stack: reason.stack,
                            message: reason.message,
                            name: reason.name,
                        },
                        reason
                    )
                )
            )
        }

        // search for app element ( used by vue )
        const appElement = document.getElementById('satisfaction-guarantee-app')

        if (appElement) {
            // create error html
            appElement.innerHTML =
                '<p>Unable to start application. Please contact admin with this information:</p><br><br>'
            const reasonElement = document.createTextNode(textReason)
            appElement.appendChild(reasonElement)
        } else {
            // print error as alert ( no html element found )
            window.alert(
                'Unable to start application. Please contact admin with this information: ' +
                textReason
            )
        }
    })
