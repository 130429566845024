<template>
  <div class="satisfaction-guarantee-wrapper">
    <SatisfactionGuaranteeFormular/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SatisfactionGuaranteeFormular from "@/components/SatisfactionGuaranteeFormular.vue";

export default defineComponent({
  name: 'App',
  components: {
    SatisfactionGuaranteeFormular
  }
});
</script>

<style lang="scss" scoped>
.satisfaction-guarantee-wrapper {
  @import "~bootstrap/dist/css/bootstrap-grid.min";
  @import "~@shared/e-vue-components/dist/einhell.theme";
}
</style>
