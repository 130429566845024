import axios from "axios";
import {ConfigFileService} from "@/service/ConfigFileService";

class InternalSerialNumberService {
    async check(serialNo:string): Promise<{ serialNo:string, valid: boolean, serialNumber:string, itemNo:string, itemVersion:string, description:string,tpgName:string, productFamily:string}> {
        const url = ConfigFileService.serialNumberServiceUrl + "api/serialnumber/"
        const res = await axios.get(url + serialNo)
            .catch(function (error){
                throw error.response.data
            })

        return res.data
    }
}

export const SerialNumberService = new InternalSerialNumberService();
