class InternalKeycloakService {
    get token(): string|number {
        const accessTokenStr = localStorage.getItem("einhell-auth-data")
        if (accessTokenStr) {
            const accessToken = JSON.parse(accessTokenStr)
            if (accessToken.token) {
                return accessToken.token
            }
        }
        return 0
    }

    get loginUrl(): string {
        const accessTokenStr = localStorage.getItem("einhell-auth-data")
        if (accessTokenStr) {
            const accessToken = JSON.parse(accessTokenStr)
            if (accessToken.loginUrl) {
                return accessToken.loginUrl
            }
        }
        return ""
    }
}

export const KeycloakService = new InternalKeycloakService()