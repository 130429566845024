import axios from "axios";
import {ConfigFileService} from "@/service/ConfigFileService";
import {KeycloakService} from "@/service/KeycloakService";
import {SatisfactionGuaranteeServiceType} from "@/Types/SatisfactionGuaranteeServiceType";

class InternalSatisfactionGuaranteeService {
    async create(data:SatisfactionGuaranteeServiceType): Promise<{ status:string, retoureId:number, fileName:string }> {
        const token = KeycloakService.token
        const url = ConfigFileService.satisfactionGuaranteeServiceUrl + "api/satisfaction-gurantee/create"

        const axiosInstance = axios.create({
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            }
        })

        const res = await axiosInstance.post(url, data)
            .catch(function (error){
                throw error.response.data
            })

        return res.data
    }
}

export const SatisfactionGuaranteeService = new InternalSatisfactionGuaranteeService();