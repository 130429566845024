import axios from "axios";
import {ConfigFileService} from "@/service/ConfigFileService";
import {systemDomain, getLocale } from "@/main";

class InternalItemRepoService {
    async getItem(sku:string): Promise<{ description:string, tpgName:string, productFamily:string }> {
        const url = ConfigFileService.itemRepoServiceUrl + `v2/item/${sku}`
        const res = await axios.get(url, {
            params: {
                site: systemDomain,
                locale: getLocale()
            }
        })
            .catch(function (error){
                throw error.response.data
            })

        return res.data
    }
}

export const ItemRepoService = new InternalItemRepoService();
