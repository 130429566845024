class InternalConfigFileService {
    private _satisfactionGuaranteeService: string | null = null
    private _serialNumberService: string | null = null
    private _itemRepoService: string | null = null
    private _successUrl: string | null = null

    async init(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.vueAppSatisfactionGuaranteeService == undefined || window.vueAppSerialNumberService == undefined || window.vueAppSuccessUrl == undefined) {
            throw new Error('Could not init application')
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._satisfactionGuaranteeService = window.vueAppSatisfactionGuaranteeService
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._serialNumberService = window.vueAppSerialNumberService
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._itemRepoService = window.vueAppItemRepoService
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._successUrl = window.vueAppSuccessUrl
    }

    get satisfactionGuaranteeServiceUrl(): string {
        if (this._satisfactionGuaranteeService) {
            return this._satisfactionGuaranteeService
        } else {
            throw new Error('Could not init application')
        }
    }

    get serialNumberServiceUrl(): string {
        if (this._serialNumberService) {
            return this._serialNumberService
        } else {
            throw new Error('Could not init application')
        }
    }

    get itemRepoServiceUrl(): string {
        if (this._itemRepoService) {
            return this._itemRepoService
        } else {
            throw new Error('Could not init application')
        }
    }

    get successUrl(): string {
        if (this._successUrl) {
            return this._successUrl
        } else {
            throw new Error('Could not init application')
        }
    }
}

export const ConfigFileService = new InternalConfigFileService()
