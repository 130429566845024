export interface CipValidationFormType {
  country: string
}

function checkCipForCountry (zip:string, form:CipValidationFormType) {
  if (zip && form.country) {
    const cipValidators = {
      'at': '^[0-9]{4}$',
      'be': '^[0-9]{4}$',
      'ba': '^[0-9]{5}$',
      'bg': '^[0-9]{4}$',
      'de': '^[0-9]{5}$',
      'cz': '^[0-9 ]{5,6}$',
      'dk': '^[0-9]{4}$',
      'fi': '^[0-9]{5}$',
      'fr': '^[0-9]{5}$',
      'gr': '[0-9- ]{5,8}$',
      'hu': '^[0-9]{4}$',
      'it': '^[0-9]{5}$',
      'lv': '[0-9- ]{6,8}$',
      'li': '^[0-9]{4,5}$',
      'lt': '[0-9- ]{5,9}$',
      'lu': '^[0-9]{4}$',
      'nl': '[0-9- ]{4,8}$',
      'pl': '[0-9- ]{5,8}$',
      'pt': '[0-9- ]{7,8}$',
      'ro': '^[0-9]{6}$',
      'rs': '^[0-9]{4,5}$',
      'es': '^[0-9]{5}$',
      'se': '[0-9- ]{5,7}$',
      'gb': '[0-9A-Za-z- ]{5,7}$',
      'cy': '[0-9]{4}$'
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const pattern = cipValidators[form.country]
    const re = new RegExp(pattern)
    return re.test(zip)
  } else {
    return true
  }
}

export { checkCipForCountry }
